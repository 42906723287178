import React from 'react';

const Imports = React.lazy(() => import('@sm360/imports'));
const ImportTypes = React.lazy(() => import('@sm360/import-types'));
const ImportDashboards = React.lazy(() => import('@sm360/import-dashboards'));
const Inventories = React.lazy(() => import('@sm360/inventories'));
const Dealers = React.lazy(() => import('@sm360/dealers'));
const DownloadCenter = React.lazy(() => import('@sm360/download-center'));
const Users = React.lazy(() => import('@sm360/user-management'));

const Navigation = [
    {
        heading: 'app.nav.ADMINISTRATION',
        heading_short: 'app.nav.ADMINISTRATION_SHORT',
    },
    {
        name: 'Inventories',
        translate: 'app.nav.INVENTORIES',
        description: 'app.nav.VEHICLES_DESCRIPTION',
        icon: 'car',
        route: '/inventories/vehicles',
        component: Inventories,
        right: 'XmsDeltaVehicles',
    },
    {
        name: 'Imports',
        translate: 'app.nav.IMPORTS',
        description: 'app.nav.IMPORTS_DESCRIPTION',
        icon: 'file-import',
        route: '/vehicles/imports/imports',
        component: Imports,
        right: 'XmsDeltaImports',
    },
    {
        name: 'Dealers',
        translate: 'app.nav.DEALERS',
        description: 'app.nav.DEALERS_DESCRIPTION',
        icon: 'store',
        route: '/dealers',
        component: Dealers,
        right: 'XmsDeltaDealers',
    },
    {
        name: 'Users',
        translate: 'app.nav.USERS',
        route: '/administration/users',
        icon: 'user-friends',
        right: 'UserManagement',
        description: 'app.nav.USERS_DESCRIPTION',
        component: Users,
        badge: 'Beta',
    },
    {
        heading: 'app.nav.OPERATIONS',
        heading_short: 'app.nav.OPERATIONS_SHORT',
    },
    {
        name: 'Dashboards',
        translate: 'app.nav.IMPORTS_DASHBOARD',
        description: 'app.nav.IMPORTS_DASHBOARD_DESCRIPTION',
        icon: 'clipboard',
        route: '/vehicles/imports/dashboards',
        component: ImportDashboards,
        right: 'XmsDeltaImportDashboard',
    },
    {
        name: 'Import Types',
        translate: 'app.nav.IMPORT_TYPES',
        description: 'app.nav.IMPORT_TYPES_DESCRIPTION',
        icon: 'cog',
        route: '/vehicles/imports/import-types',
        component: ImportTypes,
        right: 'XmsDeltaImportTypes',
    },
    {
        heading: 'app.nav.DOCUMENTS',
        heading_short: 'app.nav.DOCUMENTS_SHORT',
    },
    {
        name: 'Export Reports',
        translate: 'app.nav.DOWNLOADS',
        description: 'app.nav.DOWNLOADS_DESCRIPTION',
        icon: 'file-download',
        route: '/tools/export-reports',
        component: DownloadCenter,
        right: 'XmsDeltaVehicles',
    },
];

export default Navigation;
